<template>
  <div class="login">
    <div class="login-con">
      <img src="@/assets/logo.png" alt="" class="logo" />
      <img src="@/assets/login-title.png" alt="" class="title" />
      <div class="form-wrap">
        <el-form
          ref="ruleForm"
          :rules="rules"
          :model="ruleForm"
          label-width="80px"
        >
          <el-form-item prop="userName">
            <el-input
              v-model="ruleForm.userName"
              name="userName"
              placeholder="请填写用户名"
            >
            <template slot="prepend">用户名</template>
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              v-model="ruleForm.password"
              type="password"
              name="password"
              placeholder="请填写密码"
            >
            <template slot="prepend">&nbsp;密&nbsp;&nbsp;码&nbsp;</template></el-input>
          </el-form-item>
        </el-form>
        <div class="form-btn">
          <el-button type="primary" @click="submitForm('ruleForm')"
            >登录</el-button
          >
        </div>
      </div>
    </div>
    <!-- <p class="footer">欢迎登录视频会议系统</p> -->
  </div>
</template>

<script>
import { login } from "../api/login";
import { Toast } from 'vant'
export default {
  components: {},
  data() {
    return {
      ruleForm: {
        userName: "",
        password: "",
      },
      rules: {
        userName: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  created() {
    let token = localStorage.getItem("storage_key");
    if (token) {
      this.$router.replace({
        path: "/",
      });
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          login(this.ruleForm).then((res) => {
            if (res.successfully) {
              localStorage.setItem("storage_key", res.token);
              if (this.$route.query.redirect_url) {
                //从未登录页面跳转到登录的页面，登陆后跳回去
                let query = JSON.parse(JSON.stringify(this.$route.query));
                delete query.redirect_url;
                this.$router.replace({
                  path: this.$route.query.redirect_url,
                  query: query,
                });
              } else {
                // 登陆后跳首页
                this.$router.replace({
                  path: "/",
                });
              }
            } else {
              Toast.fail(res.msg);
            }
          });
        } else {
          return false;
        }
      });
    },

   
  },
};
</script>

<style lang="less" scoped>
.login {
  width: 100%;
  height: 100vh;
  background: url("../assets/login-bg.png") no-repeat;
  background-size: 100% 100%;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    margin: 0;
  }
  /deep/.el-form-item__content{
margin-left: 0 !important;
  }
  .login-con {
    width: 40%;
    margin: auto;
    text-align: center;
    > img {
      display: block;
    }
    .logo {
      width: 80px;
      height: 80px;
      margin: auto;
      margin-bottom: 16px;
    }
    .title {
      width: 200px;
      margin: auto;
      margin-bottom: 64px;
    }
    .form-wrap {
      width: 80%;
      margin: auto;
    }
    .form-btn {
      margin-top: 40px;
      padding-bottom:80px;
      box-sizing: border-box;
      // border-radius: 4px;
      // background: linear-gradient(95deg, #558fff 7.87%, #1464fe 110.97%);
      // box-shadow: 4px 8px 24px 0px rgba(116, 179, 238, 0.25);
      /deep/.el-button {
        border-radius: 4px;
      }
      /deep/.el-button--primary{
          width: 100%;
        }
    }
  }
  .footer {
    position: absolute;
    bottom: 32px;
    left: 0;
    width: 100%;
    text-align: center;
    color: #236efe;
    font-size: 14px;
    opacity: 0.64;
    letter-spacing: 2px;
  }
}
</style>
